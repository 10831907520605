<template>
  <v-row justify="center">
    <v-col cols="5" class="mb-5">
      <!-- Header -->
      <h2 class="pb-5 pt-5 text-center">Profile</h2>
      <!-- Loading -->
      <v-row v-if="loading" class="pt-5" justify="center">
        <v-icon>mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <!-- Error -->
      <v-row v-if="error" class="pt-5 red--text" justify="center">
        {{ error }}
      </v-row>
      <!-- User Form -->
      <UserForm :inputData="userProfileData" :isProfile="true" v-on:onSubmitComplete="onSubmitComplete"></UserForm>
    </v-col>
  </v-row>
</template>

<script>
  import UserForm from "@/components/UserForm"

  export default {
    name: 'Profile',

    components: {
      UserForm
    },

    data () {
      return {
        error: '',
        loading: false,
        userProfileData: {}
      }
    },

    created () {
      this.getUserProfile()
    },

    methods: {
      getUserProfile () {
        this.loading = true
        this.$store.dispatch('getUserProfile')
        .then((data) => {
          this.userProfileData = data
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      onSubmitComplete () {
        this.getUserProfile()
      }
    }
  }
</script>